import { gql } from '@apollo/client';

export type AcquiringSettingUpdateFields = {
    type: string;
    api_login: string;
    api_password: string;
    api_token: string;
    api_url: string;
    payment_method_id: string | null;
    payment_type_id: string | null;
    service_app_id: string;
};

export type AcquiringSettingUpdateVar = {
    id: string;
    set: AcquiringSettingUpdateFields;
};

export type AcquiringSettingUpdateResponseDataT = {
    updateAcquiringSetting: {
        id: string;
    };
} | null | undefined;

export const ACQUIRING_SETTING_UPDATE = gql`
    mutation updateAcquiringSetting(
        $set: service_app_acquiring_setting_set_input = {},
        $id: String = ""
    ) {
        updateMemberCard: update_service_app_acquiring_setting_by_pk(
            pk_columns: { id: $id },
            _set: $set
        ) {
            id
        }
    }
`;
