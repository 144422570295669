import { object, string } from 'yup';

import {
    PROPERTY_API_LOGIN, PROPERTY_API_PASSWORD,
    PROPERTY_API_TOKEN,
    PROPERTY_API_URL,
    PROPERTY_TYPE,
} from '../../../constants/propertyNames';

export type AcquiringSettingFormFields = {
    type: string;
    apiToken: string;
    apiUrl: string;
    apiLogin: string;
    apiPassword: string;
    serviceApp: string;
    paymentMethod?: string;
    paymentType?: string;
};

export const ACQUIRING_SETTING_DEFAULT_VALUES: AcquiringSettingFormFields = {
    type: '',
    apiToken: '',
    apiUrl: '',
    apiLogin: '',
    apiPassword: '',
    serviceApp: '',
    paymentMethod: '',
    paymentType: '',
};

export enum ACQUIRING_SETTING_FIELDS_KEYS {
    type = 'type',
    apiToken = 'apiToken',
    apiUrl = 'apiUrl',
    apiLogin = 'apiLogin',
    apiPassword = 'apiPassword',
    serviceApp = 'serviceApp',
    paymentMethod = 'paymentMethod',
    paymentType = 'paymentType',
}

export const FIELDS: Array<keyof typeof ACQUIRING_SETTING_FIELDS_KEYS> = [
    ACQUIRING_SETTING_FIELDS_KEYS.type,
    ACQUIRING_SETTING_FIELDS_KEYS.apiToken,
    ACQUIRING_SETTING_FIELDS_KEYS.apiUrl,
    ACQUIRING_SETTING_FIELDS_KEYS.apiLogin,
    ACQUIRING_SETTING_FIELDS_KEYS.apiPassword,
];

export const MAP_FIELDS_KEYS_TO_LABEL: Partial<Record<ACQUIRING_SETTING_FIELDS_KEYS, string>> = {
    [ACQUIRING_SETTING_FIELDS_KEYS.type]: PROPERTY_TYPE,
    [ACQUIRING_SETTING_FIELDS_KEYS.apiToken]: PROPERTY_API_TOKEN,
    [ACQUIRING_SETTING_FIELDS_KEYS.apiUrl]: PROPERTY_API_URL,
    [ACQUIRING_SETTING_FIELDS_KEYS.apiLogin]: PROPERTY_API_LOGIN,
    [ACQUIRING_SETTING_FIELDS_KEYS.apiPassword]: PROPERTY_API_PASSWORD,
};

export const getAcquiringSettingValidationSchema = () =>
    object()
        .shape({
            type: string().required(),
            apiToken: string().required(),
            apiUrl: string().required(),
            apiLogin: string().required(),
            apiPassword: string().required(),
            serviceApp: string().required(),
            paymentType: string(),
            paymentMethod: string(),
        })
        .required();
