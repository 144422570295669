import {
    PROPERTY_PAYMENT_METHOD,
    PROPERTY_PAYMENT_TYPE,
    PROPERTY_SERVICE_APP,
} from 'constants/propertyNames';

import React, { FC, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import TextField from 'shared/component/Form/TextField';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import { useStore } from 'store';
import { AcquiringSettingT } from 'types/AcquiringSetting';

import { PAYMENT_METHOD_FILTER_OPTIONS_QUERY } from '../../../shared/graphql/query/paymentMethod/PaymentMethodQuery';
import { PAYMENT_TYPE_FILTER_OPTIONS_QUERY } from '../../../shared/graphql/query/paymentType/PaymentTypeQuery';
import { SERVICE_APP_FILTER_OPTIONS_QUERY } from '../../../shared/graphql/query/serviceApp/ServiceAppQuery';

import {
    ACQUIRING_SETTING_FIELDS_KEYS,
    AcquiringSettingFormFields,
    FIELDS,
    MAP_FIELDS_KEYS_TO_LABEL,
} from './formData';

type PropsT = {
    control: Control<AcquiringSettingFormFields>;
    setValue?: UseFormSetValue<AcquiringSettingFormFields>;
    acquiringSetting?: AcquiringSettingT;
    operationType: 'create' | 'update';
};

const AcquiringSettingForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, acquiringSetting, operationType,
    } = props;

    const isUpdateForm = operationType === 'update';

    const { getFilterOption } = useStore('filterOptions');
    const serviceAppFilterOptions = getFilterOption('serviceApp');
    const paymentTypeFilterOptions = getFilterOption('paymentType');
    const paymentMethodFilterOptions = getFilterOption('paymentMethod');

    const { loading: loadingServiceApps } = useFetchFilterOptions(SERVICE_APP_FILTER_OPTIONS_QUERY, 'serviceApp');
    const { loading: loadingPaymentTypes } = useFetchFilterOptions(PAYMENT_TYPE_FILTER_OPTIONS_QUERY, 'paymentType');
    const { loading: loadingPaymentMethods } = useFetchFilterOptions(PAYMENT_METHOD_FILTER_OPTIONS_QUERY, 'paymentMethod');

    useEffect(() => {
        if (isUpdateForm && acquiringSetting && setValue) {
            Object.keys(acquiringSetting).forEach((key) => {
                if (key === 'serviceApp' || key === 'paymentType' || key === 'paymentMethod') {
                    setValue(key as keyof AcquiringSettingFormFields, acquiringSetting[key]?.id);
                } else {
                    setValue(key as keyof AcquiringSettingFormFields, acquiringSetting[key as keyof AcquiringSettingFormFields as never]);
                }
            });
        }
    }, [acquiringSetting, setValue, isUpdateForm]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            { FIELDS.map((field) => (
                <Grid2 key={ field }>
                    <TextField
                        size="small"
                        control={ control }
                        name={ field }
                        label={ MAP_FIELDS_KEYS_TO_LABEL[field] }
                        type="text"
                    />
                </Grid2>
            )) }

            <Grid2>
                <Select
                    options={ paymentMethodFilterOptions }
                    name={ ACQUIRING_SETTING_FIELDS_KEYS.paymentMethod }
                    control={ control }
                    size="small"
                    loading={ isUpdateForm ? loadingPaymentMethods : false }
                    label={ PROPERTY_PAYMENT_METHOD }
                    empty
                />
            </Grid2>

            <Grid2>
                <Select
                    options={ paymentTypeFilterOptions }
                    name={ ACQUIRING_SETTING_FIELDS_KEYS.paymentType }
                    control={ control }
                    size="small"
                    loading={ isUpdateForm ? loadingPaymentTypes : false }
                    label={ PROPERTY_PAYMENT_TYPE }
                    empty
                />
            </Grid2>

            <Grid2>
                <Select
                    options={ serviceAppFilterOptions }
                    name={ ACQUIRING_SETTING_FIELDS_KEYS.serviceApp }
                    control={ control }
                    size="small"
                    loading={ isUpdateForm ? loadingServiceApps : false }
                    label={ PROPERTY_SERVICE_APP }
                />
            </Grid2>
        </Grid2>
    );
});

export default AcquiringSettingForm;
