import { useMutation } from '@apollo/client';
import { AcquiringSettingFormFields } from 'page/InternetAcquiring/components/formData';
import {
    CREATE_ACQUIRING_SETTING,
    CreateAcquiringSettingResponseT,
    CreateAcquiringSettingT,
    CreateAcquiringSettingVarT,
    EMPTY_CREATE_ACQUIRING_SETTING_RESPONSE,
} from 'shared/graphql/mutation/acquiringSetting/AcquiringSettingCreate';

import { CreateAcquiringSettingDataT } from './CreateAcquiringSettingData';

const useAcquiringSettingCreate = (): CreateAcquiringSettingDataT => {
    const [create, { loading, error }] = useMutation<CreateAcquiringSettingT, CreateAcquiringSettingVarT>(
        CREATE_ACQUIRING_SETTING,
    );

    const createAcquiringSetting = async (createAcquiringSettingData: AcquiringSettingFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        type: createAcquiringSettingData.type,
                        api_login: createAcquiringSettingData.apiLogin,
                        api_password: createAcquiringSettingData.apiPassword,
                        api_token: createAcquiringSettingData.apiToken,
                        api_url: createAcquiringSettingData.apiUrl,
                        ...(createAcquiringSettingData.paymentMethod && { payment_method_id: createAcquiringSettingData.paymentMethod }),
                        ...(createAcquiringSettingData.paymentType && { payment_type_id: createAcquiringSettingData.paymentType }),
                        service_app_id: createAcquiringSettingData.serviceApp,
                    },
                },
            });

            return data?.adminAcquiringSettingCreate as CreateAcquiringSettingResponseT;
        } catch {
            return EMPTY_CREATE_ACQUIRING_SETTING_RESPONSE;
        }
    };

    return {
        createAcquiringSetting,
        loading,
        error,
    };
};

export default useAcquiringSettingCreate;
