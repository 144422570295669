import { MODAL_TITLE_CREATE_COACH_CATEGORY } from 'constants/modalTitles';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateCoachCategory from 'shared/hook/coachCategory/create/useCreateCoachCategory';
import { useStore } from 'store';

import {
    COACH_CATEGORY_DEFAULT_VALUES,
    COACH_CATEGORY_FIELD_KEYS, COACH_CATEGORY_LABEL_DATA,
    COACH_CATEGORY_VALIDATION_SCHEMA,
    CoachCategoryFormFields,
} from './formData';

const CreateCoachCategoryModal: FC = observer(() => {
    const { isOpen, onClose } = useStore('createCoachCategoryModal');
    const { createCoachCategory, loading: loadingCreation } = useCreateCoachCategory();
    const {
        control, handleSubmit, reset, formState: { isValid, isDirty },
    } = useForm<CoachCategoryFormFields>({
        defaultValues: COACH_CATEGORY_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(COACH_CATEGORY_VALIDATION_SCHEMA),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateCoachCategory = (data: CoachCategoryFormFields) => {
        createCoachCategory(data).then(handleCloseModal);
    };

    useEffect(() => {
        reset();
    }, [isOpen]);

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="lg">
            <ModalTitle onClose={ onClose }>{ MODAL_TITLE_CREATE_COACH_CATEGORY }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 spacing={ 2 } container padding={ 2 }>
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            name={ COACH_CATEGORY_FIELD_KEYS.name }
                            label={ COACH_CATEGORY_LABEL_DATA[COACH_CATEGORY_FIELD_KEYS.name] }
                            type="text"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loadingCreation }
                    onClick={ handleSubmit(handleUpdateCoachCategory) }
                    disabled={ !isValid || !isDirty }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateCoachCategoryModal;
