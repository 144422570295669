export const PROPERTY_TYPE = 'Тип';
export const PROPERTY_DATETIME = 'Дата';
export const PROPERTY_USER = 'Юзер';
export const PROPERTY_DOCUMENT_NUMBER = 'Номер документа';
export const PROPERTY_COURT_TYPE = 'Тип корта';
export const PROPERTY_CATEGORY = 'Категория услуг';
export const PROPERTY_GROUP_TYPE = 'Тип группы';
export const PROPERTY_SERVICE = 'Услуга';
export const PROPERTY_TITLE = 'Название';
export const PROPERTY_COLOR = 'Цвет';
export const PROPERTY_POSITION = 'Позиция';
export const PROPERTY_IS_ACTIVE = 'Активно';
export const PROPERTY_ACTIONS = 'Действия';
export const PROPERTY_DAY = 'День';
export const PROPERTY_START = 'Начало';
export const PROPERTY_END = 'Конец';
export const PROPERTY_SEASON = 'Сезон';
export const PROPERTY_CARD_PURCHASE_PRICE = 'Цена покупки карты';
export const PROPERTY_CLIENT = 'Клиент';
export const PROPERTY_VALUE = 'Величина';
export const PROPERTY_MEASUREMENT_UNIT = 'В чем измеряется';
export const PROPERTY_RECIPIENT = 'Получатель';
export const PROPERTY_CLIENT_FIRST_NAME = 'Имя клиента';
export const PROPERTY_CLIENT_MIDDLE_NAME = 'Отчество клиента';
export const PROPERTY_CLIENT_LAST_NAME = 'Фамилия клиента';
export const PROPERTY_RECIPIENT_FIRST_NAME = 'Имя получателя';
export const PROPERTY_RECIPIENT_MIDDLE_NAME = 'Отчество получателя';
export const PROPERTY_RECIPIENT_LAST_NAME = 'Фамилия получателя';
export const PROPERTY_CLIENT_FIRST_NAME_SHORT = 'Имя';
export const PROPERTY_CLIENT_MIDDLE_NAME_SHORT = 'Отчество';
export const PROPERTY_CLIENT_LAST_NAME_SHORT = 'Фамилия';
export const PROPERTY_CLIENT_BIRTHDAY = 'День рождения клиента';
export const PROPERTY_BIRTHDAY_DAY = 'День рождения';
export const PROPERTY_BIRTHDAY_MONTH = 'Месяц рождения';
export const PROPERTY_BIRTHDAY_YEAR = 'Год рождения';
export const PROPERTY_FULL_NAME = 'ФИО';
export const PROPERTY_PAYER_FIRST_NAME = 'Имя плательщика';
export const PROPERTY_PAYER_MIDDLE_NAME = 'Отчество плательщика';
export const PROPERTY_PAYER_LAST_NAME = 'Фамилия плательщика';
export const PROPERTY_MEMBER_CARD_TYPE = 'Тип членской карты';
export const PROPERTY_CARD_TYPE = 'Тип карты';
export const PROPERTY_ORGANIZATION = 'Организация';
export const PROPERTY_COACH = 'Тренер';
export const PROPERTY_GROUP = 'Группа';
export const PROPERTY_BULK_OPERATION_TYPE = 'Тип операции';
export const PROPERTY_STATUS = 'Статус';
export const PROPERTY_OPERATION = 'Операция';
export const PROPERTY_PLACE = 'Место';
export const PROPERTY_PERFORMER = 'Исполнитель';
export const PROPERTY_RATE = 'Продажа';
export const PROPERTY_TOTAL = 'Общая стоимость';
export const PROPERTY_DOCUMENT = 'Документ';
export const PROPERTY_CURRENCY = 'Валюта';
export const PROPERTY_DURATION = 'Продолжительность';
export const PROPERTY_DATETIME_OPERATION = 'Дата операции';
export const PROPERTY_DATETIME_CREATION = 'Дата создания';
export const PROPERTY_DISCOUNT = 'Скидка';
export const PROPERTY_CASHBACK = 'Кэшбэк';
export const PROPERTY_MULTIPLYING_FACTOR = 'Дополнительный коэффициент промокода';
export const PROPERTY_COMMENT = 'Комментарий';
export const PROPERTY_USE_HALVES = 'Половинчатый';
export const PROPERTY_PERIOD = 'Период';
export const PROPERTY_PERIODS = 'Периоды';
export const PROPERTY_ORDER = 'Порядок';
export const PROPERTY_BONUS_CLASSES = 'Бонусные классы';
export const PROPERTY_SCHEDULE = 'Расписание';
export const PROPERTY_COURT_COURT_CATEGORIES = 'Категории';
export const PROPERTY_COURTS_AREA = 'Площадки';
export const PROPERTY_WORK_TIME = 'Рабочее время';
export const PROPERTY_COACH_CATEGORY = 'Категория тренера';
export const PROPERTY_SERVICE_APP = 'Сервисное приложение';
export const PROPERTY_PERSONAL_MANAGER = 'Персональный менеджер';
export const PROPERTY_PERSONAL_MANAGERS = 'Персональные менеджеры';
export const PROPERTY_COACHES = 'Тренеры';
export const PROPERTY_MAIN_COACH = 'Главный тренер';
export const PROPERTY_AUXILIARY_COACHES = 'Вспомогательные тренеры';
export const PROPERTY_EXPIRATION_PERIOD = 'Срок действия членской карты';
export const PROPERTY_CASHBACK_FIAT_OPERATIONS_LIMIT = 'Количество выплат кэшбэков FIAT в течение действия членских карт';
export const PROPERTY_CASHBACK_FIAT_SUM_LIMIT = 'Максимальная величина выплаты кэшбэков';
export const PROPERTY_ENABLED_AT_MONTH = 'Включено в месяц';
export const PROPERTY_NUMBER_FULL_RETURNS = 'Количество полных возвратов';
export const PROPERTY_ROUNDS_SALES = 'Раунды продаж';
export const PROPERTY_CAMERA_ID = 'ID камеры';
export const PROPERTY_ORGANIZATION_LOCATION = 'Локация';
export const PROPERTY_AGE_TYPE = 'Возраст';
export const PROPERTY_AGE_TYPE_GROUP = 'Возрастная группа';
export const PROPERTY_MIN_SIZE = 'Минимальный размер';
export const PROPERTY_AVG_SIZE = 'Средний размер';
export const PROPERTY_MAX_SIZE = 'Максимальый размер';
export const PROPERTY_CLIENTS_FOR_MAIN_COACH = 'Количество клиентов для главного тренера';
export const PROPERTY_IGNORE_BUSY_COACHES = 'Игнорировать занятость главного тренера';
export const PROPERTY_GROUP_CONSTANT_PRICE = 'Единая цена за все занятия';
export const PROPERTY_CORRECTION_FACTORS = 'Корректирующие коэффициенты';
export const PROPERTY_DEFAULT_FIAT_PAYMENT = 'Платеж в ₽ по умолчанию';
export const PROPERTY_DEFAULT_BONUS_PAYMENT = 'Платеж в BON по умолчанию';
export const PROPERTY_FIAT_PAYMENT_DIVISIBLE = 'Делимость фиата';
export const PROPERTY_BONUS_PAYMENT_DIVISIBLE = 'Делимость бонуса';
export const PROPERTY_ERROR_MESSAGE = 'Сообщение об ошибке';
export const PROPERTY_SALE_RATE = 'Тариф продаж';
export const PROPERTY_MIN_RATE = 'Минимальный тариф';
export const PROPERTY_PROPERTY_PERFORMER_SALE_INCOME_VALUE = 'Исполнитель получит';
export const PROPERTY_RETURN_RATE = 'Тариф возврата';
export const PROPERTY_PERFORMER_RETURN_INCOME_VALUE = 'Исполнитель вернет';
export const PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS = 'Граница переноса';
export const PROPERTY_FORWARD_TRANSFER_COST_BACK_TRANSFER_COST = 'Стоимость переноса';

export const PROPERTY_INPUT_DATA = 'Входные данные';
export const PROPERTY_RETURN_FOR_SELL = 'Доля от тарифа продажи / произвольная сумма в основной валюте';
export const PROPERTY_RETURN_FOR_CANCEL = 'Доля от тарифа возврата / Произвольная сумма в основной валюте';
export const PROPERTY_WORK_DAYS = 'Рабочие дни';
export const PROPERTY_MAX_FIAT = 'Максимальная доля оплаты тарифа в основной валюте';
export const PROPERTY_MAX_BONUS = 'Максимальная доля оплаты тарифа в бонусах';
export const PROPERTY_PERFORMER_SALE_INCOME_VALUE_EXTENDED = 'Доля от тарифа продажи или фиксированная сумма, которую получит исполнитель при продаже услуги';
export const PROPERTY_PERFORMER_RETURN_INCOME_VALUE_EXTENDED = 'Доля от тарифа возврата или фиксированная сумма, которую вернет исполнитель при отмене услуги';
export const PROPERTY_FORWARD_TRANSFER_COST_BACK_TRANSFER_COST_EXTENDED = 'Стоимость переноса назад / Стоимость переноса вперед';

export const PROPERTY_IS_GROUP_AVAILABLE_HELPER_TEXT = 'Данный переключатель откроет для заполнения параметры тарификации групповой услуги';
export const PROPERTY_IS_COACH_AVAILABLE_HELPER_TEXT = 'Данный переключатель откроет для заполнения параметры тарификации тренерской услуги';
export const PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS_HELPER_TEXT = 'Количество часов до начала занятия, когда разрешен перенос занятия';
export const PROPERTY_COURT_VIDEO_ANALYTICS_NOTIFICATION_TEXT = 'Уведомление';
export const PROPERTY_COURT_VIDEO_ANALYTICS_TIME_INTERVAL = 'Интервал';

export const PROPERTY_LABEL = 'Название приложения';
export const PROPERTY_TOKEN = 'Токен';
export const PROPERTY_DESCRIPTION = 'Описание';
export const PROPERTY_URL = 'Ссылка';
export const PROPERTY_HASH = 'Хэш';
export const PROPERTY_TIMEZONE = 'Часовой пояс';
export const PROPERTY_FIAT_CURRENCY_TITLE = 'Валюта';
export const PROPERTY_VIDEO_ANALYTICS_BOT_IDS = 'Список ID чатов для уведомлений о видеоаналитике';
export const PROPERTY_DOMAIN_NAME = 'Название домена';
export const PROPERTY_SMS_SENDER_NAME = 'Отправитель SMS';
export const PROPERTY_SMS_SENDING_ENABLED = 'Отправка SMS включена';

export const PROPERTY_PHONE_NUMBER = 'Номер телефона';
export const PROPERTY_EXTENSION_PHONE_NUMBER = 'Доп. номер телефона';

export const PROPERTY_TRANSACTION_SUM = 'Сумма транзакции';
export const PROPERTY_BONUSES_SUM = 'Бонусы';
export const PROPERTY_FIAT_SUM = 'Валюта';
export const PROPERTY_PAYMENT_TYPE = 'Тип платежа';
export const PROPERTY_PAYMENT_METHOD = 'Метод платежа';
export const PROPERTY_SHOULD_CONFIRM_BY_SMS = 'Подтверждение по SMS';

export const PROPERTY_PARAMS_SALES_AND_CASHBACK = 'Параметры скидок и кэшбэков';
export const PROPERTY_PARAMS_FULL_RETURNS = 'Параметры полных возвратов';

export const PROPERTY_PATTERN_PRICE_SALE_ROUND = 'Шаблон раунда продаж';
export const PROPERTY_TITLE_CONDITIONS_SALES = 'Название условий продажи';
export const PROPERTY_ROLE_PERMISSIONS = 'Права пользователей';

export const PROPERTY_ALLOW_NEGATIVE_PAYMENTS = 'Разрешить транзакции с отрицательной величиной';

export const PROPERTY_API_LOGIN = 'Логин';
export const PROPERTY_API_PASSWORD = 'Пароль';
export const PROPERTY_API_TOKEN = 'Токен';
export const PROPERTY_API_URL = 'URL';
