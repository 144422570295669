import {
    ERROR_ACQUIRING_SETTING_DELETE,
    SUCCESS_ACQUIRING_SETTING_DELETE,
} from 'constants/notificationMessage';

import useAcquiringSettingDelete from 'shared/data-hook/acquiringSetting/delete/single/useAcquiringSettingDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { AcquiringSettingT } from 'types/AcquiringSetting';

const useDeleteAcquiringSetting = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteAcquiringSetting } = useAcquiringSettingDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const acquiringSettingDelete = async (acquiringSettingId: AcquiringSettingT['id']) => {
        const response = await deleteAcquiringSetting(acquiringSettingId);

        if (error || !response || !response.deleteAcquiringSetting) {
            showError(ERROR_ACQUIRING_SETTING_DELETE);

            return false;
        }

        showSuccess(SUCCESS_ACQUIRING_SETTING_DELETE);
        toggleRefetchTrigger();

        return true;
    };

    return { acquiringSettingDelete };
};

export default useDeleteAcquiringSetting;
